import React from "react"
import SocialIcons from '../common/SocialIcons'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/hero-left-photo-text.scss'

class HeroLeftPhotoText extends React.Component {
  bootstrapColumnsImage = this.props.imageColumnSize?this.props.imageColumnSize:8
  bootstrapColumnsText = this.props.imageColumnSize?12-this.props.imageColumnSize:4

  render() {
    return (
      <section className="hero-left-photo-text dec-section--box-top">
        <div className="container">
          <div className="row">
            <div className="col text-align-right">
                <SocialIcons type={"light"} position={"relative"} />
            </div>
          </div>
          <div className="row">
            <div className={`col-md-${this.bootstrapColumnsImage}`}>
              <GatsbyImage image={this.props.heroImage} loading="auto" alt="" />
            </div>
            <div className={`col-md-${this.bootstrapColumnsText}`}>
              <h1 dangerouslySetInnerHTML={{ __html: this.props.heroHeader }} />
              <p dangerouslySetInnerHTML={{ __html: this.props.heroText }} className="h3" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroLeftPhotoText