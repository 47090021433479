import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroLeftPhotoText from '../components/sections/hero-left-photo-text'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-designart.scss'
import { designart as servicesSchema } from '../utils/schema/services'

const pageDesignArt = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-design-art"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-designart.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 935, quality: 80, layout: CONSTRAINED)
    }
  }
  photo1: file(relativePath: {eq: "designart/photo1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo2: file(relativePath: {eq: "designart/photo2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo3: file(relativePath: {eq: "designart/photo3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo4: file(relativePath: {eq: "designart/photo4.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo5: file(relativePath: {eq: "designart/photo5.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo6: file(relativePath: {eq: "designart/photo6.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo7: file(relativePath: {eq: "designart/photo7.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  photo8: file(relativePath: {eq: "designart/photo8.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  photo9: file(relativePath: {eq: "designart/photo9.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      </Helmet>
      <Layout viewport={''}>
        <article className="page-designart">
          <HeroLeftPhotoText
            heroImage={data.hero.childImageSharp.gatsbyImageData}
            heroHeader={'Design &amp; Art'}
            heroText={'Simplicity, <br> wit and <br>good <br>typography'}
          />
          <section className="designart__content">
            <div className="container medium">
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0 designart__textbox text-align-right">
                  <h2 className="dec-header--underline">Εταιρική Ταυτότητα</h2>
                  <p>Κερδίστε το κοινό και τους συνεργάτες σας με την πρώτη ματιά. Τα πάντα αποκτούν νόημα και οι λεπτομέρειες συνθέτουν ένα ισχυρό λογότυπο σε κάθε εφαρμογή, ψηφιακή και έντυπη. Σχεδιάζουμε τα μέσα και τη μορφή επικοινωνίας της εταιρείας σας με το κοινό, ενώ παράλληλα δημιουργούμε τις βάσεις της επιτυχίας σας μέσα από τις σύγχρονες εφαρμογές επικοινωνίας. Τίποτα δεν είναι τυχαίο.</p>
                  <strong className="h5">Think Plus για τον σχεδιασμό της εταιρικής σας ταυτότητας. Επιτρέψτε μας να δημιουργήσουμε μαζί ένα πραγματικά σύγχρονο πρόσωπο για την εταιρεία σας.</strong>
                </div>
                <div className="col-md-6 order-1 order-md-0 designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo1.childImageSharp.gatsbyImageData}
                    alt="Corporate identity" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo2.childImageSharp.gatsbyImageData}
                    alt="Product identity" />
                </div>
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Προϊοντική ταυτότητα</h2>
                  <p>Επικοινωνήστε το πραγματικό μήνυμα των προϊόντων σας με το σωστό τρόπο την κατάλληλη στιγμή. Από τη συσκευασία έως το ράφι των καταστημάτων, κάθε σχεδιαστική λεπτομέρεια είναι τόσο σημαντική, όσο το ίδιο το προϊόν. Αποκτήστε ολοκληρωμένη προϊοντική ταυτότητα μέσα από την έρευνα, τις προτιμήσεις και τις επιταγές της σύγχρονης εποχής. Είναι τόσο σημαντικό.</p>
                  <strong className="h5">Think Plus για τον σχεδιασμό της ταυτότητας των προϊόντων σας και ας δημιουργήσουμε μαζί τις βάσεις της επιτυχίας και των πωλήσεων.</strong>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0 designart__textbox text-align-right">
                  <h2 className="dec-header--underline">Διαφημιστικά έντυπα</h2>
                  <p>Αποκτήστε τα εργαλεία επικοινωνίας που θα σας βοηθήσουν να πετύχετε κάθε στόχο. Στην εποχή του internet και των smartphones, τα παραδοσιακά flyers, τα folders και οι επαγγελματικές κάρτες είναι ακόμα απαραίτητα εργαλεία και επικοινωνούν όσα θέλετε να μεταφέρετε στους πελάτες και τους συνεργάτες σας. Ακριβώς αυτό που χρειάζεστε.</p>
                  <strong className="h5">Think Plus για το σχεδιασμό και την εκτύπωση των έντυπων μέσων που έχετε ανάγκη.</strong>
                </div>
                <div className="col-md-6 order-1 order-md-0 designart__photobox col-no-padding">
                  <GatsbyImage image={data.photo3.childImageSharp.gatsbyImageData} alt="Advertising" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 designart__photobox col-no-padding">
                  <GatsbyImage image={data.photo4.childImageSharp.gatsbyImageData} alt="Publishing" />
                </div>
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Εκδόσεις</h2>
                  <strong className="h5">Think Plus για τις εκτυπώσεις και τις εκδόσεις βιβλίων και ας δώσουμε μαζί μορφή στο έργο σας. </strong>
                  <p>Σημαντικό έργο το οποίο πρέπει να εκτυπωθεί και να μοιραστεί στα κατάλληλα χέρια. Ένα σύγχρονο έντυπο, ένα μοναδικό βιβλίο γνώσης που χρειάζεται έκδοση. Τυπώνουμε offset και ψηφιακά το πολύτιμο έργο σας ενώ σχεδιάζουμε προσεκτικά κάθε βήμα της διαδικασίας, από το στήσιμο του βιβλίου μέχρι την τελική παράδοση του ολοκληρωμένου έργου. Άψογο design και επαγγελματισμός.</p>
                </div>
              </div>
              <div className="row">
                <div className="col designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo5.childImageSharp.gatsbyImageData}
                    alt="Offset and digital printing" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Εκτυπώσεις μεγάλου όγκου</h2>
                  <h3 className="h5">offset printing</h3>
                  <p>Παραγωγή εντύπων που ξεπερνούν τα 1000 τεμάχια. Σκέφτεστε offset εκτύπωση τιμοκαταλόγων, flyers, folders, προσκλητηρίων και βιβλίων.  Διαθέτουμε πιεστήρια τελευταίας τεχνολογίας και τυπώνουμε έντυπα έγχρωμα και ασπρόμαυρα μέσα από μία μεγάλη γκάμα επιλογών εκτύπωσης από απλά ή ειδικά χαρτιά μέχρι 70*100cm, με standard χρώματα (CMYK) ή Pantone και φινίρισμα με βερνίκι, UV, κτλ. Συνεργασία σε κάθε στάδιο.</p>
                  <strong className="h5">Think Plus για την εκτύπωση των έργων μεγάλου όγκου που χρειάζεστε και μαζί θα ορίσουμε την επιτυχία σας, άμεσα και οικονομικά.</strong>
                </div>
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Ψηφιακές εκτυπώσεις μικρού όγκου &amp; μεταβλητών δεδομένων</h2>
                  <h3 className="h5">digital printing</h3>
                  <p>Αποκτήστε πρόσβαση σε μια ευρεία γκάμα επιλογών ψηφιακής εκτύπωσης.  Αναλαμβάνουμε ψηφιακές εκτυπώσεις για την παραγωγή εντύπων με μικρό τιράζ σε μηχανήματα εκτυπώσεων τελευταίας τεχνολογίας και design για 4-χρωμία ή ασπρόμαυρη εκτύπωση, σε απλά ή ειδικά χαρτιά και μεγέθη που φτάνουν έως και 33*49cm. Άριστη ποιότητα σε κάθε εκτύπωση.</p>
                    <strong className="h5">Think Plus για τις δικές μοναδικές ανάγκες ψηφιακής εκτύπωσης με τέλεια ποιότητα, άμεσο χρόνο παράδοσης και ανταγωνιστικές τιμές.</strong>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0 designart__textbox text-align-right">
                  <h2 className="dec-header--underline">Ψηφιακές εκτυπώσεις μεγάλων διαστάσεων</h2>
                  <h3 className="h5">wide format printing</h3>
                  <strong className="h5">Think Plus για τις δικές μοναδικές ανάγκες εκτύπωσης μεγάλων διαστάσεων και μαζί θα δημιουργήσουμε έργα με αψεγάδιαστη ποιότητα και ανταγωνιστικές τιμές. </strong>
                  <p>Ήρθε η ώρα να εκτυπώσετε το δικό σας πόστερ, το δικό σας μπάνερ μεγάλων διαστάσεων. Αναλαμβάνουμε την ψηφιακή εκτύπωση posters, σχεδίων ή banners που φτάνουν έως και τα 3 μέτρα. Μέσα από μια μεγάλη γκάμα υλικών εκτύπωσης όπως βινύλιο, χαρτί photo gloss, καμβάς, μουσαμάς κ.ά. Σχεδιάζουμε τις ανάγκες σας σε ένα πλήθος αρχείων format προς εκτύπωση (Autocad, Photoshop, Illustrator, Indesign κ.α.). Η δικής σας επιλογή.</p>
                </div>
                <div className="col-md-6 order-1 order-md-0 designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo6.childImageSharp.gatsbyImageData}
                    alt="Wide format printing" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 designart__photobox col-no-padding">
                  <GatsbyImage image={data.photo7.childImageSharp.gatsbyImageData} alt="Packaging" />
                </div>
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Συσκευασίες</h2>
                  <strong className="h5">Think Plus για το σχεδιασμό και την υλοποίηση της συσκευασίας που έχει πραγματικά ανάγκη το προϊόν σας και μαζί θα το μεταφέρουμε μέχρι τα χέρια των καταναλωτών.</strong>
                  <p>Κάθε προϊόν έχει τη δική του μοναδική συσκευασία, είναι η δική του εστία. Μεταφέρουμε το μήνυμα και την πραγματική αξία του μέσα από το άψογο design και τη στρατηγική επικοινωνία προς κάθε ενδιαφερόμενο. Αναλαμβάνουμε τον σχεδιασμό και την εκτύπωση πρωτότυπων συσκευασιών που θα επικοινωνήσουν αποτελεσματικά το προϊόν σας. Τυπώνουμε σε συσκευασίες κουτιών με ή χωρίς UV ή πλαστικοποίηση, με ή χωρίς παράθυρο, σε διαφόρων ειδών κλεισίματα. Προϊόν και συσκευασία με αξία.</p>
                </div>
              </div>
              <div className="row">
                <div className="col designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo8.childImageSharp.gatsbyImageData}
                    alt="Heat transfer and screen printing" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Θερμοτυπίες</h2>
                  <h3 className="h5">heat trasnfer printing</h3>
                  <p>Χιλιάδες μορφές προϊόντων και εφαρμογών που χρειάζονται το κάτι παραπάνω.  Αναλαμβάνουμε μεγάλη γκάμα εκτυπώσεων θερμοτυπίας που καλύπτουν τις εμπορικές-διαφημιστικές σας ανάγκες. Διαθέτουμε μηχανές θερμοτυπίας τελευταίας τεχνολογίας για θερμοτυπικές εκτυπώσεις σε μπλούζες, φόρμες, καπέλα, mousepads, κούπες κ.α. Ζωντάνια και πολυχρωμία.</p>
                  <strong className="h5">Think Plus για κάθε ανάγκη και εφαρμογή θερμοτυπικών εκτυπώσεων βγαλμένων από το μέλλον της τεχνολογίας και της βιομηχανικής εξέλιξης.</strong>
                </div>
                <div className="col-md-6 designart__textbox text-align-left">
                  <h2 className="dec-header--underline">Μεταξοτυπίες</h2>
                  <h3 className="h5">screen printing</h3>
                  <p>Ανακαλύψτε μια πληθώρα εφαρμογών μεταξοτυπίας που καλύπτουν τις εμπορικές και διαφημιστικές σας ανάγκες. Διαθέτουμε άρτια εξοπλισμένο εργαστήριο μεταξοτυπίας για εκτύπωση σε υφάσματα (T-shirt, jockey κ.α.), σε αυτοκόλλητα, PVC και διαφημιστικά μικροαντικείμενα (αναπτήρες, στυλό, τσάντες, USB stick, μπρελόκ κ.α.). Πραγματική ποιότητα που φαίνεται.</p>
                  <strong className="h5">Think Plus για την εκτύπωση μεταξοτυπιών που κάνουν τη διαφορά και επικοινωνούν την αξία των προϊόντων σας σε κάθε εφαρμογή.</strong>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0 designart__textbox text-align-right">
                  <h2 className="dec-header--underline">3d printing services</h2>
                  <strong className="h5">Think Plus για 3D εφαρμογές που ξεπερνούν τα στάνταρ της αγοράς και ελάτε να δημιουργήσουμε έναν νέο κόσμο επικοινωνίας και προώθησης.</strong>
                  <p>Η τεχνολογική επανάσταση της τρισδιάστατης εκτύπωσης έχει ήδη κατακτήσει την αγορά. Διαθέτουμε τον τεχνολογικό εξοπλισμό και το καταρτισμένο προσωπικό για άριστες 3d εκτυπώσεις με άψογο design. Παράγουμε τα δικά σας 3d αντικείμενα μέσα από μία μεγάλη γκάμα υλικών, που εσείς επιλέγετε. Όπως ακριβώς το σχεδιάσατε.</p>
                </div>
                <div className="col-md-6 order-1 order-md-0 designart__photobox col-no-padding">
                  <GatsbyImage
                    image={data.photo9.childImageSharp.gatsbyImageData}
                    alt="3D printing services" />
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageDesignArt
